

































import Vue from 'vue';
import HealthProfessionFieldUtils, {
  HealthProfessionField,
} from '@/utils/HealthProfessionFieldUtils';
import { DataTableHeader } from 'vuetify';

export default Vue.extend({
  computed: {
    headers(): DataTableHeader<HealthProfessionField>[] {
      return [
        {
          text: this.$tc('healthProfessionField.name'),
          value: 'name',
        },
        {
          text: this.$tc('healthProfessionField.key'),
          value: 'key',
        },
        {
          text: this.$tc('healthProfessionField.deployment.label'),
          value: 'deployment',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },

  data: (): {
    items?: HealthProfessionField[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = undefined;
      this.items = await HealthProfessionFieldUtils.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleEdit(item: HealthProfessionField) {
      await this.$router.push({
        name: 'health-profession-field-edit',
        params: { id: item.id?.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'health-profession-field-create',
      });
    },
  },
});
